import { BulkAddCommService } from './../../common/facet/bulk-add-comm.service';
import { BulkAddResult } from '../../common/facet/models/bulk-edit.classes';
import { BulkAddExitReason } from '../../common/facet/models/bulk-add-exit-reason.enum';
import { BulkEditSection } from '../../common/facet/models/bulk-edit-section.enum';
import { BulkEditOptions } from '../../common/facet/models';
import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';

import { AnimalService } from '../services/animal.service';
import { EnumerationService } from '../../enumerations/enumeration.service';
import { JobService } from '../../jobs/job.service';
import { LineService } from '../../lines/line.service';
import { LocationService } from '../../locations/location.service';
import { MaterialService } from '../../services/material.service';
import { MaterialPoolService } from '../../services/material-pool.service';
import { NamingService } from '../../services/naming.service';
import { VocabularyService } from '../../vocabularies/vocabulary.service';
import { AnimalLogic } from '../animal-logic.shared';
import {
  empty,
    notEmpty,
    sortObjectArrayByProperty,
    testBreezeIsNew,
} from '../../common/util';
import { GenotypeService } from '../../genotypes/genotype.service';
import { SettingService } from '../../settings/setting.service';
import { Animal, BulkCreateAnimal, BulkCreateAnimalRequestBody, Entity, Line, LocationPosition, Order, TaxonCharacteristic, cv_AnimalMatingStatus, cv_AnimalStatus, cv_ContainerType, cv_MaterialPoolType } from '@common/types';
import { BulkAddComponent, FacetView, IFacet } from '@common/facet';
import { IValidatable, SaveChangesService } from '@services/save-changes.service';
import { ToastrService } from '@services/toastr.service';
import { LogLevel } from '@services/models';
import { TranslationService } from '@services/translation.service';
import { NgModel } from '@angular/forms';
import { AnimalBulkTemplatesComponent } from './animal-bulk-templates.component';
import { PermitService } from '../../permits/services/permit.service';
import { OrderService } from 'src/app/orders/order.service';
import { set } from 'lodash';

class BulkHousingUnit {
    // Housing ID
    id: string;
    // Number of housed animals
    size: number;

    constructor(size: number) {
        this.size = size;
    }
}

export class BulkHousing {
    // External State
    //
    // Number of animals, updated by the bulk-add component
    itemsToAdd = 1;
    // Is Housing auto-naming active?
    namingActive = false;

    // Internal State
    //
    // Has the Create Housing section been initialized?
    enabled = false;
    // Number of unhoused animals
    unhoused = 0;
    // Housing unit config (size, ID)
    units: BulkHousingUnit[] = new Array<BulkHousingUnit>();

    // Form Elements
    //
    // Are new housing units being created?
    add = false;
    // Number of animals per unit
    size = 5;
    // Housing Type
    materialPoolTypeKey: number;
    materialPoolTypes: cv_MaterialPoolType[] = [];
    // Date
    date: Date = new Date();
    // Owner
    owner: string | null;
    // Location
    location: LocationPosition | null;
    // Container Type
    containerTypeKey: number;
    containerTypes: cv_ContainerType[] = [];

    materialPoolStatusKey: number;
}

@Component({
    selector: 'animal-bulk-add',
    templateUrl: './animal-bulk-add.component.html',
    providers: [
        BulkAddCommService
    ],
    styles: [`
      .housing .form-group .col-md-3 {
         text-align: right;
         padding-right: 0;
      }
      .housing-size {
          direction: rtl;
          padding: 3px 5px;
          width: 50px;
       }
      .housing-units,
      .housing-units .input-group {
          margin-bottom: 5px;
      }
      .housing-unit-id    { min-width: 100px; max-width: 100px; }
      .housing-unit-size  { min-width:  50px; max-width:  50px; }
    `],
})
export class AnimalBulkAddComponent implements OnInit, OnDestroy, AfterViewInit, IValidatable {
    @ViewChild('bulkTemplates') bulkTemplates: AnimalBulkTemplatesComponent;
    @ViewChild('bulkAdd') bulkAdd: BulkAddComponent;
    @ViewChild('dateControl') dateControl: NgModel;

    @Input() facet: IFacet;
    @Input() facetView: FacetView;
    // Active and required fields set by facet settings
    @Input() activeFields: string[];
    @Input() requiredFields: string[];

    @Output() exit: EventEmitter<BulkAddResult> = new EventEmitter<BulkAddResult>();

    animals: Entity<Animal>[];
    housings: any[];
    animalNamingActive = false;

    // shared logic for animal facet
    animalLogic: AnimalLogic;
    bulkHousing: BulkHousing;

    readonly COMPONENT_LOG_TAG = 'animal-bulk-add';

    BulkEditSection = BulkEditSection;

    animalContainerDefaultKey: any = null;

    orderId: number | null;
    isOrderIdAlreadyUsed: boolean;
    isOrderLoading = false;

    constructor(
        private animalService: AnimalService,
        private bulkAddCommService: BulkAddCommService,
        private enumerationService: EnumerationService,
        private jobService: JobService,
        private lineService: LineService,
        private locationService: LocationService,
        private materialService: MaterialService,
        private materialPoolService: MaterialPoolService,
        private namingService: NamingService,
        private vocabularyService: VocabularyService,
        private genotypeService: GenotypeService,
        private settingService: SettingService,
        private saveChangesService: SaveChangesService,
        private toastrService: ToastrService,
        private translationService: TranslationService,
        private permitService: PermitService,
        private orderService: OrderService

    ) {
        this.animalLogic = new AnimalLogic(
            animalService,
            enumerationService,
        );
    }

    // lifecycle
    ngOnInit(): void {
        this.saveChangesService.registerValidator(this);
        this.initialize();
    }

    ngOnDestroy(): void {
        this.saveChangesService.unregisterValidator(this);
    }

    ngAfterViewInit(): void {
        if (this.bulkTemplates?.bulkOptions) {
            this.bulkTemplates.bulkOptions.saveButtonValidators.push(() => {
                return this.animalNamingActive;
            });
        }
    }

    initialize(): void {
        // Check if animal is automatically named. May need to be moved to more appropriate spot.
        this.namingService.isAnimalNamingActive().then((active: boolean) => {
            this.animalNamingActive = active;
        });
        this.initBulkHousing();
    }

    /**
     * The "Save and Clear Form" button has been used.
     *
     * @param options
     */
    formClear(options: BulkEditOptions): void {
        // Reset the Bulk Housing
        this.initBulkHousing();
        options.__addObject.DateOrigin = new Date();
    }

    async saveClicked(result: BulkAddResult): Promise<void> {
        result.newItems = [];
        const values = await this.vocabularyService.getCVByFieldEquals(
            'cv_AnimalStatuses',
            'C_AnimalStatus_key',
            result.initialValues.C_AnimalStatus_key,
            false
        ) as any;
        if (!result.initialValues.Material.C_Line_key &&
            result.reason !== BulkAddExitReason.Edit &&
            result.reason !== BulkAddExitReason.Cancel) {
            const errorMessage = `An Animal requires a ${this.translationService.translate('Line')}.`;
            this.toastrService.showToast(this.saveChangesService.generateSaveErrorMessage(this.facet.FacetName, this.facetView, errorMessage), LogLevel.Error);
            this.bulkAddCommService.saveCanceled();
        } else if (values && values.IsExitStatus && this.bulkHousing.add) {
            this.toastrService.showToast("Animals in an 'End-State' Status cannot be housed. Please change the status or deselect the 'Create new housing units' option.", LogLevel.Warning);
            this.bulkAddCommService.saveCanceled();
            return;
        }
        switch (result.reason) {
            case BulkAddExitReason.Cancel:
                // do nothing on cancel
                return;
            case BulkAddExitReason.Save:
                try {
                    this.animals = await this.createNewAnimals(result.numberItemsToAdd, result.initialValues);
                    this.housings = await this.createNewHousings(this.bulkHousing, this.animals);
                    await this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG, false);
                } catch (error) {
                    for (const animal of this.animals) {
                        this.animalService.cancelAnimal(animal);
                    }
                    for (const housing of this.housings) {
                        this.materialPoolService.cancelMaterialPool(housing);
                    }
                    this.bulkAddCommService.saveCanceled();
                }
                break;
            case BulkAddExitReason.Edit:
                this.animals = await this.createNewAnimals(result.numberItemsToAdd, result.initialValues);
                this.housings = await this.createNewHousings(this.bulkHousing, this.animals);
                break;
        }

        result.newItems = this.animals;
        this.bulkAddCommService.saveComplete();
        this.isOrderIdAlreadyUsed = true;
    }

    async validate(): Promise<string> {
        const translatedAnimal = this.translationService.translate('Animal');

        let errorMessage = this.bulkTemplates?.validate?.() ?? '';

        for (const animal of this.animals) {
            if (this.animalNamingActive && testBreezeIsNew(animal)) {
                const invalidField = await this.animalService.validateAnimalNamingField(animal);
                if (invalidField) {
                    return `The ${this.translationService.translate(invalidField)} field is required for automatic naming.`;
                }
            } else if (empty(animal.AnimalName)) {
                return `A ${translatedAnimal} requires a Name.`;
            }

            if (!animal.Material.C_Line_key) {
                return `An ${translatedAnimal} requires a ${this.translationService.translate('Line')}.`;
            }

            errorMessage = await this.settingService.validateRequiredFields(this.requiredFields, animal, 'animal');
            if (errorMessage) {
                break;
            }
        }

        return errorMessage;
    }

    exitClicked(result: BulkAddResult): void {
        this.exit.emit(result);
    }

    async createNewAnimals(numberItemsToAdd: number, initialValues: BulkCreateAnimalRequestBody): Promise<Entity<Animal>[]> {
        const animals: Entity<Animal>[] = [];
        const promises: Promise<unknown>[] = [];

        // Get all defaults for using
        const characteristicsDefaults = {};
        if (notEmpty(initialValues.TaxonCharacteristicInstance)) {
            for (const characteristic of initialValues.TaxonCharacteristicInstance) {
                if (characteristic.CharacteristicValue) {
                    characteristicsDefaults[characteristic.C_TaxonCharacteristic_key] = characteristic.CharacteristicValue;
                }
            }
        }
        initialValues.characteristicsDefaults = characteristicsDefaults;

        if (!initialValues.C_AnimalStatus_key) {
            promises.push(this.vocabularyService.getCVDefault('cv_AnimalStatuses')
                .then((value: cv_AnimalStatus) => {
                    initialValues.cv_AnimalStatus = value;
                }));
        }

        promises.push(this.vocabularyService.getCVDefault('cv_AnimalMatingStatuses')
            .then((value: cv_AnimalMatingStatus) => {
                initialValues.cv_AnimalMatingStatus = value;
            }));

        // Get line default stuff
        if (initialValues.Material.C_Line_key) {
            promises.push(this.lineService.getLine(initialValues.Material.C_Line_key).then((line: Line) => {
                initialValues.cv_Taxon = line.cv_Taxon;
            }));
        }
        if (initialValues.Material.C_Taxon_key) {
            promises.push(this.animalService.getActiveCharacteristics(initialValues.Material.C_Taxon_key).then((characteristics: TaxonCharacteristic[]) => {
                initialValues.characteristics = characteristics;
            }));
        }

        if (initialValues.C_Permit_key) {
            promises.push(this.permitService.getPermit(initialValues.C_Permit_key, ['Resource']).then(permit => {
                initialValues.C_Permit_key = permit.C_Permit_key;
            }));
        }

        // Get MaterialTypeKey for Animal Type Materials
        promises.push(this.materialService.getMaterialTypeKey('Animal').then((materialTypeKey: number) => {
            initialValues.C_MaterialType_key = materialTypeKey;
        }));

        return Promise.all(promises).then(() => {
            for (let i = 0; i < numberItemsToAdd; i++) {
                animals.push(this.createNewAnimal(initialValues));
            }

            return animals;
        });
    }

    createNewAnimal(initialValues: BulkCreateAnimal): Entity<Animal> {
        const newMaterial = this.materialService.create(initialValues);
        newMaterial.MicrochipIdentifier = initialValues.Material.MicrochipIdentifier;
        newMaterial.ExternalIdentifier = initialValues.Material.ExternalIdentifier;
        const newAnimal = this.animalService.create();
        newAnimal.C_Sex_key = initialValues.C_Sex_key;
        newAnimal.C_Generation_key = initialValues.C_Generation_key;
        newAnimal.C_Diet_key = initialValues.C_Diet_key;
        newAnimal.ShipmentID = initialValues.ShipmentID;
        newAnimal.VendorID = initialValues.VendorID;
        newAnimal.C_Order_key = initialValues.C_Order_key;
        newAnimal.DateBorn = initialValues.DateBorn;
        newAnimal.DateExit = initialValues.DateExit;
        newAnimal.C_ExitReason_key = initialValues.C_ExitReason_key;
        newAnimal.PhysicalMarker = initialValues.PhysicalMarker;
        newAnimal.Owner = initialValues.Owner;
        newAnimal.AlternatePhysicalID = initialValues.AlternatePhysicalID;
        newAnimal.Comments = initialValues.Comments;
        newAnimal.Material = newMaterial;
        newAnimal.TaxonCharacteristics = [];
        newAnimal.HeldFor = initialValues.HeldFor;
        newAnimal.CITESNumber = initialValues.CITESNumber;
        newAnimal.C_Permit_key = initialValues.C_Permit_key;

        if (initialValues.DateOrigin) {
            newAnimal.DateOrigin = initialValues.DateOrigin;
        } else {
            newAnimal.DateOrigin = this.requiredFields.includes('DateOrigin') ? null : new Date();
        }
        if (initialValues.C_AnimalStatus_key) {
            newAnimal.C_AnimalStatus_key = initialValues.C_AnimalStatus_key;
        } else {
            newAnimal.cv_AnimalStatus = initialValues.cv_AnimalStatus;
        }

        if (initialValues.cv_AnimalMatingStatus) {
            newAnimal.cv_AnimalMatingStatus = initialValues.cv_AnimalMatingStatus;
        }

        newAnimal.C_AnimalUse_key = initialValues.C_AnimalUse_key;
        newAnimal.C_BreedingStatus_key = initialValues.C_BreedingStatus_key;
        newAnimal.C_AnimalClassification_key = initialValues.C_AnimalClassification_key;
        newAnimal.C_IACUCProtocol_key = initialValues.C_IACUCProtocol_key;
        newAnimal.C_PhysicalMarkerType_key = initialValues.C_PhysicalMarkerType_key;
        newAnimal.Material.C_MaterialOrigin_key = initialValues.Material.C_MaterialOrigin_key;

        if (initialValues.Material.C_Line_key && initialValues.cv_Taxon) {
            newMaterial.cv_Taxon = initialValues.cv_Taxon;

            newMaterial.C_Line_key = initialValues.Material.C_Line_key;
            if (newAnimal.TaxonCharacteristicInstance) {
                this.animalLogic.deleteTaxons(newAnimal);
            }
            if (initialValues.TaxonCharacteristicInstance) {
                this.animalService.createTaxonCharacteristicsWithInitialValues(newAnimal, initialValues.TaxonCharacteristicInstance);
            }
        }

        if (initialValues.JobKey) {
            this.addJobMaterial(newAnimal, initialValues.JobKey);
        }

        if (initialValues.Genotype) {
            for (const genotype of initialValues.Genotype) {
                const assay = genotype.cv_GenotypeAssay.C_GenotypeAssay_key;
                const symbol = genotype.cv_GenotypeSymbol.C_GenotypeSymbol_key;
                this.addGenotypeRecord(newAnimal, assay, symbol);
            }
        }
        return newAnimal;
    }

    setCharacteristicDefaults(animal: Entity<Animal>, initialValues: any): Entity<Animal> {
        if (!notEmpty(animal.TaxonCharacteristicInstance) ||
            !notEmpty(initialValues.TaxonCharacteristicInstance)
        ) {
            return animal;
        }

        for (const characteristic of animal.TaxonCharacteristicInstance) {
            if (initialValues.characteristicsDefaults[characteristic.C_TaxonCharacteristic_key]) {
                characteristic.CharacteristicValue = initialValues.characteristicsDefaults[characteristic.C_TaxonCharacteristic_key].CharacteristicValue;
            }
        }
        return animal;
    }

    addJobMaterial(animal: Entity<Animal>, jobKey: number): void {
        const materialKey = animal.C_Material_key;
        this.jobService.createJobMaterial({
            C_Material_key: materialKey,
            C_Job_key: jobKey,
            DateIn: new Date(),
            Version: 0
        });
    }

    addGenotypeRecord(animal: Entity<Animal>, assayKey: number, symbolKey: number): void {
        if (!assayKey) {
            return;
        }
        this.genotypeService.create({
            C_Material_key: animal.C_Material_key,
            C_GenotypeAssay_key: assayKey,
            C_GenotypeSymbol_key: symbolKey
        });
    }

    itemsToAddChanged(itemsToAdd: number): void {
        // Changed the number of animals - start over
        this.bulkHousing.itemsToAdd = itemsToAdd;
        this.housingRebuildUnits();
    }

    initBulkHousing(): void {
        this.bulkHousing = new BulkHousing();

        const preferLocal = true;

        const promises: Promise<void>[] = [
            // Check if housing is automatically named.
            this.namingService.isHousingNamingActive().then((active: boolean) => {
                this.bulkHousing.namingActive = active;
            }),

            // Housing Type
            this.vocabularyService.getCV(
                'cv_MaterialPoolTypes', 'MaterialPoolType', preferLocal
            ).then((data) => {
                this.bulkHousing.materialPoolTypes = data;
            }),
            this.vocabularyService.getCVDefault('cv_MaterialPoolTypes').then((data) => {
                if (data && data.C_MaterialPoolType_key) {
                    this.bulkHousing.materialPoolTypeKey = data.C_MaterialPoolType_key;
                }
            }),

            // Container Type
            this.locationService.getContainerTypes('Animal').then((data) => {
                this.bulkHousing.containerTypes = data;
            }),
            this.vocabularyService.getCVContainerTypeDefault('Animal').then((data) => {
                if (data && data.C_ContainerType_key) {
                    this.bulkHousing.containerTypeKey = data.C_ContainerType_key;
                }
            }),

            // Location
            this.locationService.getDefaultLocation().then((data) => {
                this.bulkHousing.location = data;
            }),

            this.vocabularyService.getCVDefault('cv_MaterialPoolStatuses').then((data) => {
                if (data && data.C_MaterialPoolStatus_key) {
                    this.bulkHousing.materialPoolStatusKey = data.C_MaterialPoolStatus_key;
                }
            })
        ];

        Promise.all(promises).then(() => {
            // All set, enable the checkbox
            this.bulkHousing.enabled = true;
        });
    }

    housingAddChanged(): void {
        // Distribute the animals
        this.housingRebuildUnits();
    }

    housingSizeChanged(): void {
        // Redistribute the animals
        this.housingRebuildUnits();
    }

    housingRebuildUnits(): void {
        if (!this.bulkHousing.add) {
            // Not adding housing, so nothing to do
            return;
        }

        if (!this.bulkHousing.size) {
            // Can't fill units without a size
            return;
        }

        const units = new Array<BulkHousingUnit>();

        // Distribute the animals by filling housing units.
        // Note: this leaves animals unhoused rather than partially fill a
        // housing unit. The user will need to decide what to do with those
        // unhoused animals.
        let items = this.bulkHousing.itemsToAdd;
        while (items >= this.bulkHousing.size) {
            // Fill up a new unit
            const unit = new BulkHousingUnit(this.bulkHousing.size);
            units.push(unit);

            // Account for the now-housed animals
            items -= unit.size;
        }

        // Use these new units
        this.bulkHousing.units = units;

        // Count the unhoused (again, this time officially)
        this.housingUpdateUnhoused();
    }

    housingUnitChanged(unit: BulkHousingUnit): void {
        // Deal with typed-in negative numbers or clearing the input
        unit.size = Math.max(0, unit.size);

        // How many still left?
        this.housingUpdateUnhoused();

        if (this.bulkHousing.unhoused < 0) {
            // Too many animals have been moved into this housing
            unit.size += this.bulkHousing.unhoused;

            // Look for unhoused animals - just in case... should be 0.
            this.housingUpdateUnhoused();
        }
    }

    housingUpdateUnhoused(): void {
        // Add up all the housed animals
        const housed = this.bulkHousing.units.reduce(
            (sum: number, unit: BulkHousingUnit) => sum + unit.size
            , 0);

        // So, how many did not get a house?
        this.bulkHousing.unhoused = this.bulkHousing.itemsToAdd - housed;
    }

    housingAddUnit(): void {
        // Add a new unit, and try to fill it with the remaining animals
        this.bulkHousing.units.push(
            new BulkHousingUnit(Math.min(this.bulkHousing.unhoused, this.bulkHousing.size))
        );

        // See who else needs a house
        this.housingUpdateUnhoused();
    }

    housingDeleteUnit(_: BulkHousingUnit, index: number): void {
        // Remove the unit
        this.bulkHousing.units.splice(index, 1);

        // See if any animals got kicked out
        this.housingUpdateUnhoused();
    }

    createNewHousings(bulkHousing: BulkHousing, newAnimals: Entity<Animal>[]): Promise<any[]> {
        if (!bulkHousing.add) {
            // Nothing to do
            return Promise.resolve([]);
        }

        // Make sure the animals are in a consistent order
        sortObjectArrayByProperty(newAnimals, 'C_Material_key', true);

        // Going to need to keep track of which animal is being housed.
        let animalIndex = 0;

        // Create each housing unit sequentially so the animal names are in the
        // same order as the housing unit names. Promises are weird.
        const newHousings: any[] = [];
        for (const unit of bulkHousing.units) {
            // Create a new housing unit, aka MaterialPool
            const materialPool = this.materialPoolService.createMaterialPool({
                MaterialPoolID: unit.id,
                C_MaterialPoolType_key: bulkHousing.materialPoolTypeKey,
                C_ContainerType_key: bulkHousing.containerTypeKey,
                DatePooled: bulkHousing.date,
                Owner: bulkHousing.owner,
                C_MaterialPoolStatus_key: bulkHousing.materialPoolStatusKey
            });

            // Put the housing unit somewhere
            this.locationService.createMaterialLocation({
                C_MaterialPool_key: materialPool.C_MaterialPool_key,
                C_LocationPosition_key: bulkHousing.location.C_LocationPosition_key,
            });

            // Put animals in this housing unit
            let items = unit.size;
            while (items > 0) {
                // Get the next animal
                const animal = newAnimals[animalIndex];
                if (!animal) {
                    // Ran out of animals... weird
                    break;
                }

                // Put the animal in the housing unit
                const mpm = this.materialPoolService.createMaterialPoolMaterial({
                    C_MaterialPool_key: materialPool.C_MaterialPool_key,
                    C_Material_key: animal.C_Material_key,
                    DateIn: bulkHousing.date,
                });
                animal.Material.MaterialPoolMaterial.unshift(mpm);

                // Account for the used-up space and housed animal
                items -= 1;
                animalIndex += 1;
            }

            // All done with this one.
            newHousings.push(materialPool);
        }

        // All done
        return Promise.resolve(newHousings);
    }

    async checkSelectedOrder(orderId: number | null): Promise<void> {
        this.isOrderIdAlreadyUsed = orderId == null
            ? false
            : await this.orderService.isOrderUsedForAnimals(orderId);
    }

    async onOrderIdSelect(orderId: number | null): Promise<void> {
        this.bulkTemplates.bulkOptions.__addObject.C_Order_key = orderId;

        if (orderId == null) {
            this.isOrderIdAlreadyUsed = false;
            return;
        }

        if (!this.bulkTemplates) {
            return;
        }

        this.isOrderLoading = true;

        try {
            this.checkSelectedOrder(orderId)

            const order = <Entity<Order>>await this.orderService.getOrder(orderId, ['OrderLocation.LocationPosition']);

            this.bulkAdd.addState.numberItemsToAdd = order.AnimalCount ?? 1;

            this.updateValueFromOrder(['Material', 'C_Line_key'], order.C_Line_key);

            this.updateValueFromOrder(['C_Permit_key'], order.C_Permit_key);
            this.bulkTemplates.onPermitSelect(order.C_Permit_key, BulkEditSection.AddScreen)

            this.updateValueFromOrder(['Material', 'C_MaterialOrigin_key'], order.C_MaterialOrigin_key);
            this.updateValueFromOrder(['C_Sex_key'], order.C_Sex_key);
            this.updateValueFromOrder(['C_Diet_key'], order.C_Diet_key);
            this.updateValueFromOrder(['DateOrigin'], order.DateProjectedReceipt ?? new Date());
            this.updateValueFromOrder(['JobKey'], order.C_Job_key);

            this.bulkHousing.location = order.OrderLocation.length > 0 ? order.OrderLocation[0].LocationPosition : null;
        } finally {
            this.isOrderLoading = false
        }
    }

    private updateValueFromOrder(fieldToUpdate: string[], newValue: any) {
        set(this.bulkTemplates.bulkOptions.__addObject, fieldToUpdate, newValue)
    }

    // <select> formatters
    materialPoolTypeKeyFormatter = (value: cv_MaterialPoolType): number => {
        return value.C_MaterialPoolType_key;
    }
    materialPoolTypeFormatter = (value: cv_MaterialPoolType): string => {
        return value.MaterialPoolType;
    }
    containerTypeKeyFormatter = (value: cv_ContainerType): number => {
        return value.C_ContainerType_key;
    }
    containerTypeFormatter = (value: cv_ContainerType): string => {
        return value.ContainerType;
    }
}
